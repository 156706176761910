
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import _date from "@wedjs/date";

@Component({
    filters: {
        dateTime(val: string | number) {
            return _date.date("Y-m-d H:i:s", Number(val) * 1000);
        },
        number(val: string) {
            return val ? Number(val) : "";
        },
    },
})
export default class LogTable extends Vue {
    @Prop(Array) list!: any[];
}
