
import { Component, Vue, Inject } from "vue-property-decorator";
import { houseStatistics } from "@/api";
import LogTable from "@/components/LogTable.vue";
import _date from "@wedjs/date";

@Component({ components: { LogTable } })
export default class Detail extends Vue {
    @Inject() passwordInit!: () => void;
    private select = [0, 30, 90, 365];
    private select_value = 0;
    private show = false;
    private date_start = "";
    private date_end = "";
    private activeNames = [];

    private cost = 0;
    private rent = 0;
    private deposit = 0;
    private total = 0;
    private list = [];

    created() {
        this.passwordInit();
        this.calendarInit();
        this.houseStatistics();
    }

    async houseStatistics() {
        const { data } = await houseStatistics({
            start: this.date_start,
            end: this.date_end,
        });
        this.cost = data.cost;
        this.rent = data.rent;
        this.deposit = data.deposit;
        this.total = data.total;
        this.list = data.list;
    }

    calendarInit() {
        this.date_end = _date.date("Y-m-d");
        if (this.select_value) {
            this.date_start = _date.date(
                "Y-m-d",
                new Date().getTime() - (this.select_value - 1) * 86400000
            );
        } else {
            this.date_start = _date.date("Y-01-01");
        }
    }
    handleTag(item: number) {
        this.select_value = item;
        this.calendarInit();
        this.houseStatistics();
    }
    handleCalendar([start, end]: Date[]) {
        this.date_start = _date.date("Y-m-d", start.getTime());
        this.date_end = _date.date("Y-m-d", end.getTime());
        if (this.date_end === _date.date("Y-m-d")) {
            if (this.date_start === _date.date("Y-01-01")) {
                this.select_value = 0;
            } else {
                const diff =
                    new Date(_date.date("Y-m-d")).getTime() -
                    new Date(this.date_start).getTime();
                this.select_value = diff / 86400000 + 1;
            }
        }
        this.show = false;
        this.houseStatistics();
    }
}
